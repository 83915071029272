import React from 'react';
import Header from '../components/Header'
import '../styles/success-page.css'
import Footer from '../components/Footer';
import Logo from '../img/logo.svg'

const SubmissionError = () => {

    return(
        <div id="submission_error">
            <Header />
                <div className="content">
                    <img id="content_logo" src={Logo} alt="insurance321 logo" />
                    <h1 className="green">We've Encountered an Error</h1>
                    <p>Unfortunately, we have encountered an error processing your submission.</p>
                    <p>We promise, we still want to work with you.</p>
                    <p>Please give us a call at: <div className="green upsize"><strong>(234) 230-6321</strong></div> ...and an agent will process your request right away.</p>
                </div>
            <Footer />
        </div>
    )
}

 
export default SubmissionError;